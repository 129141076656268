const baseUrl = {
  custom: "https://forms.gle/WfXxAY6mm2e7cskg6",
  dapp: "https://app.encent.io/#/project",
  // whitepaper: 'https://drive.google.com/file/d/1KbYyw1gejzlnIZEaYKmdYh4m36-eFXb1/view',
  whitepaper: "https://saasgo.xyz/whitepaper.pdf",
  encentive: "https://discord.gg/BxKGBqhNGT", //footer-icon
  github: "https://github.com/EncentiveProtocol",
  twitter: "https://twitter.com/SaaSGoOfficial",
  telegram: "https://t.me/SaaSGo_Official",
  medium: "https://medium.com/@encentive",
  helpCenter:
    "https://docs.google.com/document/d/1Oee254Vs6OjMAt6rjwJsMcTyBWfw8x68_3qLQFx17Kc/edit", // footer-name
  building:
    "https://medium.com/@encentive/encentive-the-best-building-tool-in-the-dex-era-8941da632d04",
  dex: "https://medium.com/@encentive/encentive-dex-building-made-easy-597d4c06ab05",
  deFi: "https://medium.com/@encentive/article-defi-development-tools-to-pay-attention-to-36f8d72e6afb",
  docsMedium: "https://medium.com/@encentive",
  mailbox: "mailto:support@saasgo.xyz",
  product: "",
  auditReport:
    "https://drive.google.com/file/d/1XYhxmL4mtgq8_5pg0_JaTXx9BOvItx04/view?usp=sharing",
  video1: "https://www.youtube.com/watch?v=8lQd3J9aSlE",
  video2: "https://www.youtube.com/watch?v=mJuotNQTsO8",
  video3: "https://www.youtube.com/watch?v=nsW7zJSX380",
  // subscribe: "http://18.222.137.245:9080/encentive/subscribe",
  subscribe: "http://api.encentive.io/encentive/subscribe",
  learn: "https://www.youtube.com/channel/UC79Jlw65A3utllYtn_c8CWA",
  linktr: "https://linktr.ee/Encentive_io",
  bounties: "https://app.dework.xyz/encentive-ambassador",
  email: "support@saasgo.xyz",
  website: "https://saasgo.xyz",
};
export default {
  baseUrl,
};
